import { FC, useEffect } from "react";
import { navigate } from "gatsby";

const NotFoundPage: FC = () => {
  useEffect(() => {
    navigate("/");
  }, []);

  return null;
};

export default NotFoundPage;
